import React, { useState, useEffect } from 'react'
import { Table } from '@radix-ui/themes'
import '@radix-ui/themes/styles.css'
import tableStyles from './styles/downloadsTable.module.scss'
import { refreshUserData } from '../services/auth'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { downloadMetadata } from '../services/payload'
import { getTokenIcon } from '../services/token'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

export default function DownloadsTable () {
  const [inFetch, setInFetch] = useState(false)
  const [downloads, setDownloads] = useState(false)

  // Download DOM functionality
  const download = async (metadataId) => {
    try {
      const downloadRes = await downloadMetadata(metadataId)
      const urlCreator = window.URL || window.webkitURL
      const href = urlCreator.createObjectURL(downloadRes)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', metadataId)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    const getDownloads = async () => {
      try {
        // Get user download history
        setInFetch(true)
        const user = await refreshUserData()
        const downloads = user.downloads
        console.log('downloads', downloads)

        // Fetch icon for each download data.
        for (let i = 0; i < downloads.length; i++) {
          try {
            const downloadData = downloads[i]
            const tokenIcon = await getTokenIcon(downloadData.tokenId)
            downloads[i].tokenIcon = tokenIcon
          } catch (error) {
            continue
          }
        }
        setDownloads(downloads)
        setInFetch(false)
      } catch (error) {
        console.warn(error)
        setInFetch(false)
      }
    }

    getDownloads()
  }, [])
  return (
    <>
      {inFetch && (
        <PropagateLoader
          color='rgb(59, 159, 111)'
          loading={inFetch}
          size={7}
          cssOverride={{
            display: 'block',
            textAlign: 'center'
          }}
          speedMultiplier={1}
          style={{ position: 'absolute', bottom: '20%', left: '50%' }}
        />
      )}
      <Table.Root>
        {!inFetch && (
          <Table.Header>
            <Table.Row className={tableStyles.textStyle}>
              <Table.ColumnHeaderCell justify='center'>
                Token Icon
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Token ID</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell justify='center'>
                NFT Name
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
        )}

        <Table.Body>
          {downloads && downloads.length && (
            <>
              {downloads.map((val, i) => {
                return (
                  <Table.Row
                    key={`download-${i}`}
                    className={tableStyles.textStyle}
                  >
                    <Table.Cell justify='center' py='1rem'>
                      <img
                        key={`icon-${i}`}
                        alt={`${val.metadataName}-icon`}
                        src={val.tokenIcon}
                        className={tableStyles.icon}
                      />
                    </Table.Cell>
                    <Table.RowHeaderCell py='1rem' justify='start'>
                      <a
                        href={`${TOKEN_EXPLORER}/transactions/?txid=${val.tokenId}`}
                        target='_blank'
                        without
                        rel='noreferrer'
                      >
                        {val.tokenId &&
                          `${val.tokenId.slice(0, 4)}...${val.tokenId.slice(
                            -4
                          )}`}
                      </a>
                    </Table.RowHeaderCell>
                    <Table.Cell justify='center' py='1rem'>
                      {val.metadataName}
                    </Table.Cell>
                    <Table.Cell justify='end'>
                      {' '}
                      <button
                        className={tableStyles.downloadBtn}
                        onClick={() => {
                          download(val.metadataId)
                        }}
                      >
                        Download
                      </button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </>
          )}
        </Table.Body>
      </Table.Root>
    </>
  )
}
